var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entitymapslisting"
  }, [_c('v-row', {
    staticClass: "page-header mb-16 mt-8"
  }, [_c('v-col', [_c('r-page-header', {
    staticClass: "entitymapslisting--pageheader",
    attrs: {
      "label": _vm.$t('entityMaps.entityMapsListing.title')
    },
    scopedSlots: _vm._u([{
      key: "end",
      fn: function () {
        return [_c('r-button', {
          attrs: {
            "label": _vm.$t('entityMaps.addEntityMap.title'),
            "icon": "add"
          },
          on: {
            "click": _vm.onCreateNewEntityMap
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-row', {
    staticClass: "ma-0 mt-16 pt-8"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('r-search-input', {
    staticClass: "mr-2",
    staticStyle: {
      "width": "300px",
      "flex-grow": "0"
    },
    attrs: {
      "value": _vm.search
    },
    on: {
      "change": _vm.onChangeSearch
    }
  }), _c('r-button', {
    staticClass: "entitymaps-delete--button",
    staticStyle: {
      "height": "32px !important"
    },
    attrs: {
      "outlined": "",
      "label": _vm.$t('global.buttons.delete'),
      "disabled": _vm.deleteDisabled,
      "loading": _vm.fetching
    },
    on: {
      "click": _vm.onClickDelete
    }
  })], 1)], 1), _vm.search ? _c('v-col', {
    staticClass: "pa-0 entitieslisting--chiprow",
    attrs: {
      "cols": "12"
    }
  }, [_c('r-chip', {
    attrs: {
      "removable": "",
      "label": _vm.search
    },
    on: {
      "click": function ($event) {
        _vm.search = '';
      }
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-2 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('entity-maps-table', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "change:batch-select": _vm.onChangeBatchSelection,
      "change:batch-select-all": _vm.onSelectAll
    }
  }), _vm.confirmDelete ? _c('r-modal', {
    attrs: {
      "action-label": _vm.$t('deleteConfirmation.delete'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "fetching": _vm.fetching,
      "title": _vm.$t('entityMaps.deleteEntityMap.title')
    },
    on: {
      "close": function ($event) {
        _vm.confirmDelete = false;
      },
      "submit": _vm.onConfirmDelete
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchError]
          }
        }), _c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('entityMaps.deleteEntityMap.text')) + " ")])];
      },
      proxy: true
    }], null, false, 3751549885)
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }