var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "entitymapstable"
  }, [_vm.fetchError ? _c('r-error-message', {
    staticClass: "entitymapstable--errormessage",
    attrs: {
      "errors": [_vm.fetchError]
    }
  }) : _vm._e(), _vm.entityMaps.length ? _c('r-table', {
    staticClass: "entitymapstable--table",
    attrs: {
      "columns": _vm.tableColumns,
      "rows": _vm.entityMaps,
      "total-rows": _vm.totalEntityMaps,
      "page": _vm.page,
      "page-size": _vm.pageSize,
      "loading": _vm.fetching
    },
    on: {
      "change:page": function (_ref) {
        var value = _ref.value;
        return _vm.setPage({
          page: value
        });
      },
      "change:page-size": _vm.onChangePageSize,
      "change:page-size-all": _vm.onChangePageSizeAll,
      "sort:asc": function (_ref2) {
        var value = _ref2.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'asc'
        });
      },
      "sort:desc": function (_ref3) {
        var value = _ref3.value;
        return _vm.setSort({
          sort: value,
          sortDir: 'desc'
        });
      },
      "change:batch-select": function (data) {
        return _vm.$emit('change:batch-select', data);
      },
      "change:batch-select-all": function (data) {
        return _vm.$emit('change:batch-select-all');
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": `/entitymaps/${item._id}`
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.lastEdited",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.lastEdited ? _c('span', [_vm._v(_vm._s(_vm.formatDate(item.lastEdited)))]) : _c('span', [_vm._v("Never")])];
      }
    }, {
      key: "item.created",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          staticClass: "entitymapstable--btn mr-3",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(`/entitymaps/${item._id}`);
            }
          }
        }, [_c('v-icon', [_vm._v("edit")])], 1), _c('v-btn', {
          staticClass: "entitymapstable--btn",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.onClickDelete(item);
            }
          }
        }, [_c('v-icon', [_vm._v("delete")])], 1)];
      }
    }], null, false, 1545567714)
  }) : _vm._e(), _vm.confirmDelete ? _c('r-modal', {
    attrs: {
      "title": _vm.$t('entityMaps.deleteEntityMap.title'),
      "close-action-label": _vm.$t('global.actions.cancel'),
      "action-label": _vm.$t('deleteConfirmation.delete'),
      "fetching": _vm.fetching
    },
    on: {
      "submit": _vm.onConfirmDelete,
      "close": function ($event) {
        _vm.confirmDelete = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchError]
          }
        }), _c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_vm._v(" " + _vm._s(_vm.$t('entityMaps.deleteEntityMap.text')) + " ")])];
      },
      proxy: true
    }], null, false, 3751549885)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }