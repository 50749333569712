<template>
  <div class="entitymapslisting">
    <v-row class="page-header mb-16 mt-8">
      <v-col>
        <r-page-header
          class="entitymapslisting--pageheader"
          :label="$t('entityMaps.entityMapsListing.title')"
        >
          <template v-slot:end>
            <r-button
              :label="$t('entityMaps.addEntityMap.title')"
              icon="add"
              @click="onCreateNewEntityMap"
            />
          </template>
        </r-page-header>
      </v-col>
    </v-row>

    <v-row class="ma-0 mt-16 pt-8">
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-row
          class="ma-0"
          justify="end"
        >
          <r-search-input
            :value="search"
            class="mr-2"
            style="width:300px;flex-grow:0;"
            @change="onChangeSearch"
          />
          <r-button
            outlined
            class="entitymaps-delete--button"
            :label="$t('global.buttons.delete')"
            :disabled="deleteDisabled"
            :loading="fetching"
            style="height:32px !important"
            @click="onClickDelete"
          />
        </v-row>
      </v-col>

      <v-col
        v-if="search"
        class="pa-0 entitieslisting--chiprow"
        cols="12"
      >
        <r-chip
          removable
          :label="search"
          @click="search = ''"
        />
      </v-col>

      <v-col
        class="pa-2 pb-0"
        cols="12"
      >
        <entity-maps-table
          :search="search"
          @change:batch-select="onChangeBatchSelection"
          @change:batch-select-all="onSelectAll"
        />
        <r-modal
          v-if="confirmDelete"
          :action-label="$t('deleteConfirmation.delete')"
          :close-action-label="$t('global.actions.cancel')"
          :fetching="fetching"
          :title="$t('entityMaps.deleteEntityMap.title')"
          @close="confirmDelete = false"
          @submit="onConfirmDelete"
        >
          <template v-slot:content>
            <r-error-message :errors="[fetchError]" />

            <div class="d-flex justify-center align-center">
              {{ $t('entityMaps.deleteEntityMap.text') }}
            </div>
          </template>
        </r-modal>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RButton from '@/components/library/atoms/RButton'
import RPageHeader from '@/components/library/molecules/RPageHeader'
import RSearchInput from '@/components/library/molecules/RSearchInput'
import RChip from '@/components/library/atoms/RChip'
import EntityMapsTable from '@/components/app/tools/tables/EntityMapsTable'
import RModal from '@/components/library/organisms/RModal.vue'
import RErrorMessage from '@/components/library/atoms/RErrorMessage.vue'
import { track } from '@/tracking'

export default {
  name: 'EntityMapsListing',
  components: {
    RErrorMessage,
    RModal,
    RButton,
    RPageHeader,
    RSearchInput,
    RChip,
    EntityMapsTable,
  },
  data: () => ({
    search: '',
    allSelected: false,
    selectedEntityMapIds: [],
    confirmDelete: false,
  }),
  computed: {
    ...mapGetters('entityMaps', [
      'fetching',
      'fetchError',
    ]),
    deleteDisabled() {
      return !this.$data.allSelected && !this.$data.selectedEntityMapIds.length
    },
  },
  methods: {
    ...mapActions('entityMaps', [
      'deleteEntityMaps',
      'fetchEntityMaps',
    ]),
    onChangeSearch({ value }) {
      this.$data.search = value
    },
    onChangeBatchSelection({ value: selectedItems }) {
      this.$data.allSelected = false
      this.$data.selectedEntityMapIds = selectedItems.map(({ _id }) => _id)
    },
    onSelectAll() {
      this.$data.allSelected = true
      this.$data.selectedEntityMapIds = []
    },
    onClickDelete() {
      this.confirmDelete = true
    },
    async onConfirmDelete() {
      const { search, allSelected, selectedEntityMapIds: entityMapIds } = this.$data
      this.confirmDelete = false

      if (allSelected) {
        await this.deleteEntityMaps({ filters: { search } })
      } else {
        await this.deleteEntityMaps({ entityMapIds })
      }
      this.fetchEntityMaps()
    },
    onCreateNewEntityMap() {
      track('reviews tools page - create new entity map clicked')
      this.$router.push('/entitymaps/add')
    },
  },
}
</script>
